<template>
  <div class="contentShowBox m-t1">
    <!-- 头部菜单 -->
    <div class="flex" v-if="isNavOperation">
      <div v-if="isBtnShow" class="m-r2">
        <a-button type="primary" @click="handleAdd">{{ btnTitle }}</a-button>
      </div>
      <div class="ant">
        <a-space>
          <a-popover :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode;
              }
            " placement="bottom" trigger="click" arrow-point-at-center>
            <template #content>
              <div>
                <a-checkbox-group v-model:value="state.checkColumns" @change="changeCheckColumns">
                  <div v-for="column in state.columns" :key="column.dataIndex">
                    <a-checkbox :value="column.dataIndex">{{ column.title }}</a-checkbox>
                  </div>
                </a-checkbox-group>
              </div>
            </template>
            <template #title>
              <div class="flex ju-between">
                <span>{{ $t("columnTitle") }}</span>
                <a @click="columnReset">{{ $t("columnReset") }}</a>
              </div>
            </template>
            <a-button>
              <ControlOutlined :rotate="90" />列展示
            </a-button>
          </a-popover>

          <a-button @click="reload">
            <RedoOutlined :rotate="240" />刷新
          </a-button>
        </a-space>
      </div>
    </div>
    <!-- 表格 -->
    <div class="m-t2">
      <a-table :size="size" bordered :pagination="pagination" @change="changePage" :loading="searchLoading" :columns="columns" :data-source="dataList" :rowKey="(record, index) => { return index;}">
        <template #sort="{ index }">
          {{ index + 1 }}
        </template>
        <template #content="{ record }">
          <a-popover trigger="hover">
            <template #content>
              <div style="width: 350px;">
                <div  v-html="record.content"></div>
              </div>
            </template>
            <span class="contentHover">{{ record.content }}</span>
          </a-popover>
        </template>
        <template #isShow="{ record }">
          <a-popconfirm ref="popRef" ok-text="确定" cancel-text="取消" @confirm="swtichBtn(record)" overlayClassName="LY" :getPopupContainer="(triggerNode) => {return triggerNode.parentNode;}">
            <template #icon>
              <div class="titleTips flex ju-between al-center">
                <span>提示</span>
              </div>
            </template>
            <template #title>
              <div v-if="record.isEnable.value==1" class="showTips">确认要隐藏吗？</div>
              <div v-else class="showTips">确认要显示吗？</div>
            </template>
            <a-switch :checkedValue="0" :unCheckedValue="1" size="small" :checked="record.isEnable.value" />
          </a-popconfirm>
        </template>
        <template #statistics="{ record }">
          <a @click="statistics(record)">{{ record.statistics }}</a>
        </template>
        <template #disposeState="{ record }">
           <div  v-if="record.isDeal.value == 0">
               <a @click="disposeAdd(record)">未处理</a> 
           </div>
           <div v-if="record.isDeal.value == 1">
                 已处理({{record.processor }})  {{ record.dealContent }}
           </div>
        </template>
        <template #edit="{ record }">
          <a @click="edit(record)">{{ record.edit1 }}</a>
          <a-popconfirm :title="record.edit2Tips" ok-text="确定" cancel-text="取消" @confirm="confirm(record)" @cancel="cancel">
            <a class="m-l2">{{ record.edit2 }}</a>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive ,onMounted} from "vue";
import { RedoOutlined, ControlOutlined } from "@ant-design/icons-vue";
import { useRoute } from 'vue-router'
const currentRoute = useRoute()
const props = defineProps({
  pagination: {
    type: Object,
    default: () => { },
  },
  btnTitle: {
    type: String,
    default: "增加",
  },
  isBtnShow: {
    type: Boolean,
    default: true,
  },
  isNavOperation: {
    type: Boolean,
    default: true,
  },
  columns: {
    type: Array,
    default: [],
  },
  dataList: {
    type: Array,
    default: [],
  },
  reload: {
    type: Function || Boolean,
  },
  searchLoading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'default'
  }
});
const state = reactive({
  columns: props.columns,
  checkColumns: props.columns.map((column) => {
    return column.dataIndex;
  }),
});
const emit = defineEmits([
  "handleAdd",
  "edit",
  "cancel",
  "swtichBtn",
  "update:columns",
  "changePage",
  "confirm",
  "statistics",
  "disposeAdd"
]);
//事件处理
const disposeAdd = (val) => {
  emit("disposeAdd",val);
}
//统计
const statistics = (val) => {
  emit('statistics', val)
}
const handleAdd = () => {
  emit("handleAdd");
};
//编辑
const edit = (item) => {
  emit("edit", item);
};
//分页
const changePage = (val) => {
  emit("changePage", val);
};
const cancel = (item) => {
  emit("cancel", item);
};
//删除
const confirm = (item) => {
  emit("confirm", item);
};
const swtichBtn = (item) => {
  emit("swtichBtn", item);
};
// 重置
const columnReset = () => {
  state.checkColumns = state.columns.map((column) => {
    return column.dataIndex;
  });
  emit("update:columns", state.columns);
  window.sessionStorage.setItem(currentRoute.name, JSON.stringify(state.columns))
};
const changeCheckColumns = (checkItem) => {
  emit(
    "update:columns",
    state.columns.filter((column) => checkItem.includes(column.dataIndex))
  );
    let list = JSON.stringify(state.columns.filter(column => checkItem.includes(column.dataIndex)))
    window.localStorage.setItem(currentRoute.name, list)
};
onMounted (()=>{
  if (window.localStorage.getItem(currentRoute.name)) {
        let list = JSON.parse(window.localStorage.getItem(currentRoute.name))
        emit("update:columns",list);
        state.checkColumns= list.map(column => { return column.dataIndex })
      }
})
</script>

<style lang="less" scoped>
.contentShowBox {
  padding: 20px 10px;
  background: #fff;
  .ant {
    :deep(.ant-btn) {
      background-color: #f0f2f5;
      border: none;
    }

    :deep(.ant-btn:hover) {
      color: #fff;
      border-color: #172b4d;
      background-color: #172b4d;
    }
  }
  .editBox {
    min-width: 30px;
  }

  :deep(.ant-popover-inner-content) {
    padding: 12px 15px !important;
  }

  .contentHover {
    overflow: hidden;
    text-overflow: ellipsis;
    /*将对象作为弹性伸缩盒子模型显示*/
    display: -webkit-box;
    /*限制文本行数*/
    -webkit-line-clamp: 2;
    /*子元素的排列方式*/
    -webkit-box-orient: vertical;
  }
}
</style>
